<template>
    <div>    
        <b-card>
            <div class="head-btn">
                <b-button
                    :to="{ name: 'create-sub-condominiums'}"               
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="primary"                                  
                >
                    <feather-icon icon="PlusIcon" style="color: white" />
                </b-button>
            </div>  

            <SubCondominiumsList />

        </b-card>
    </div>
</template>

<script>

import { mapState, mapActions, mapMutations } from "vuex"
import SubCondominiumsList from "@/modules/fivesClub/components/catalogs/housing/SubCondominiumsList"
import Ripple from 'vue-ripple-directive'
    
export default {
    directives: {Ripple, },
    components:{        
        SubCondominiumsList
    },
    async created() {
        await this.getInitialData()
    },
    computed: {
        ...mapState('start', ['hotels']),                                  
    },
    methods:{
        ...mapActions('fivesClubCatalogs', ['getInitialContentListHousing', 'fetchHousing']),
            ...mapMutations('fivesClubCatalogs',['setCondos','setSubCondos']),       

            ...mapMutations('start',['setHotels']),                        
            async getInitialData(){
                const { condos, subCondos } = await this.getInitialContentListHousing();
                this.setCondos(condos)
                this.setSubCondos(subCondos)                
            },
    }
   
};
</script>

<style scoped>
.head-btn{
    display: flex;
    justify-content: flex-end;
    margin-bottom: 1rem;
}
</style>